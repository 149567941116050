import * as React from "react"
import { Container, Row, Col, Button } from 'react-bootstrap';
import Header from "../components/header"
import FixedFooter from "../components/footer"

// styles
const headingStyles = {
    marginTop: 140,
    marginBottom: 100,
}

const buttonStyles = {
  backgroundColor: "#9b3838",
  borderColor: "#9b3838"
}

// markup
const VenuePage = () => {
  return (
    <main>
      <title>Wedding and Event Venue Information</title>
      <Header></Header>
      <Container style={headingStyles}>
        <Row>
            <div className="col-sm-8">
              <h2>The Venue</h2>
              <p>
                Fieldstone Farm is a the perfect venue for your memorable events!
              </p>
              <p>  
                It is available as a full house rental or venue rental for your:
                <ul>
                  <li>Intimate Weddings</li>
                  <li>Rehearsal Dinner</li>
                  <li>Bridal Tea or Brunch</li>
                  <li>Corporate Meeting or Retreat</li>
                  <li>Women's Weekend</li>
                  <li>Family Reunion</li>
                  <li>Workshop or Training Seminar</li>
                </ul>
              </p>

              <h3>For Meetings:</h3>
              <p>
                <ul>
                  <li>Large meeting/dining area seats 12 comfortably</li>
                  <li>Numerous breakout rooms for discussions</li>
                  <li>Large flat screen TV, projector, access for printing/copying</li>
                  <li>Outdoor terrace for meeting or dining</li>
                </ul>
              </p>

              <h3>For Special Events:</h3>
              <p>
                <ul>
                  <li>Outdoor terrace can be tented, seating up to 60</li>
                  <li>Indoor space for up to 40 guests</li>
                  <li>Large kitchen for caterer use</li>
                  <li>18th Century dining room seats 12</li>
                  <li>Dressing rooms for the Bride and attendants</li>
                  <li>Preferred caters list provided</li>
                </ul>
              </p>            

              <h3>For Overnight Guests:</h3>
              <p>
                <ul>
                  <li>Six bedrooms (1 King, 4 Queen, 1 with 2 Twin beds)</li>
                  <li>Swim in the pool (open late April through early October)</li>
                  <li>Stroll through numerous gardens</li>
                  <li>Hike the paths</li>
                  <li>Enjoy the wood burning fireplaces</li>
                  <li>Borrow a book from the extensive library</li>
                  <li>Continental or full breakfast can be arranged for you in the morning</li>
                </ul>
              </p>

              <h3>Policies:</h3>
              <p>
                <ul>
                  <li>No smoking indoors, on the porches, or on the terrace area by the house</li>
                  <li>No pets</li>
                  <li>Children are welcome</li>
                </ul>
              </p>

            </div>
            <div className="col-sm-4">
                <Row className="align-items-center">
                    <Col className="d-flex justify-content-center">
                    </Col>
                </Row>                
            </div>            
        </Row>

        <div class="text-center">
        <h1>Pricing</h1>
        </div>
        <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
          <div class="col">
            <div class="card mb-4 rounded-3 shadow-sm">
              <div class="card-header py-3">
                <h4 class="my-0 fw-normal">Full Inn</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$1,200</h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>Private use of entire property for overnight guests</li>
                  <li>Six guest rooms</li>
                  <li>Accommodations for 14 guests</li>
                </ul>
                <Button href="/contact" className="w-100 btn btn-lg btn-primary" style={buttonStyles}>Check availability</Button>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card mb-4 rounded-3 shadow-sm">
              <div class="card-header py-3">
                <h4 class="my-0 fw-normal">Partial Inn</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$1,000</h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>Private use of entire property for overnight guests</li>
                  <li>Three guest rooms</li>
                  <li>Accommodations for 6 guests</li>
                </ul>
                <Button href="/contact" className="w-100 btn btn-lg btn-primary" style={buttonStyles}>Check availability</Button>
              </div>
            </div>
          </div>
          <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
              <div class="card-header py-3">
                <h4 class="my-0 fw-normal">Event Space</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">Contact Us</h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>Private use of entire inn and grounds for five hours</li>
                  <li>Indoor space for 40 guests</li>
                  <li>Outdoor space for 60 guests</li>
                </ul>
                <Button href="/contact" className="w-100 btn btn-lg btn-primary" style={buttonStyles}>Check availability</Button>
              </div>
            </div>
          </div>
        </div>

      </Container>
      <FixedFooter/>      
    </main>
  )
}

export default VenuePage